// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CourseList from './components/CourseList';
import { BookingForm } from './components/BookingForm';
import { BookingSuccess } from './components/BookingSuccess';
import { generateTheme } from './theme';
import { ThemeProvider } from '@mui/material/styles';

function App() {
  const [themeColor, setThemeColor] = useState("#808080"); // Neutral grey for spinner before the real color setting is loaded
  const [font, setFont] = useState("");

  return (
    <ThemeProvider theme={generateTheme(themeColor, font)}>
    <Router>
      <Routes>
        <Route path="/" element={<CourseList setThemeColor={setThemeColor} setFont={setFont} />} />
        <Route path="/book/:courseId" element={<BookingForm />} />
        <Route path="/success" element={<BookingSuccess />} />
      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;