import { useLocation } from 'react-router-dom';
import {
    Container,
    Paper,
    Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export function BookingSuccess() {
    const { state } = useLocation(); // Get the state passed from BookingForm
    const translations = state?.translations || {}; // Default to empty object if no translations


    return (
        <Container maxWidth="sm">
            <Paper style={{ padding: "2em", marginTop: "2em", marginBottom: "2em", textAlign: 'center' }} variant='outlined'>
                
                {/* Large Success Icon */}
                <CheckCircleIcon color="success" style={{ fontSize: 100, marginBottom: "0.5em" }} />

                <Typography variant="h5" gutterBottom>
                    {translations.ThanksBookingRequest}
                </Typography>

                <Typography variant="body1" paragraph>
                    {translations.EmailConfirmation}
                </Typography>
            </Paper>
        </Container>
    );
}