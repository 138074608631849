import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Paper,
  Box,
  CircularProgress,
  Stepper,
  Step,
  MobileStepper,
  StepLabel,
  Snackbar,
  Alert
} from '@mui/material';
import { Turnstile } from '@marsidev/react-turnstile'
import { API_BASE_URL } from '../constants';

export function BookingForm() {
  const { courseId } = useParams();

  const navigate = useNavigate();

  const { state } = useLocation(); // Get the state passed from CourseList
  const translations = state?.translations || {}; // Default to empty object if no translations
  const course = state?.course || null;

  // State for error messages
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);

  // State for mobile detection
  const [isMobile, setIsMobile] = useState(false);

  const [turnstileToken, setTurnstileToken] = React.useState(null);

  const [numberOfCourseParticipants, setNumberOfCourseParticipants] = useState(1);

  const [formData, setFormData] = useState({
    iframeKey: getQueryParameterByName("iframeKey"),
    courseId: parseInt(courseId, 10),
    companyName: '',
    companyOrgNo: '',
    companyCity: '',
    companyStreet2: '',
    companyZipCode: '',
    purchaseOrderNo: '',
    pdfInvoiceAddress: '',
    extraMessage: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    nbrOfParticipants: 1,
    participants: [],
    turnstileToken: '',
  });
  const [gdprConsent, setGdprConsent] = useState(false);


  useEffect(() => {
    // Check for mobile on component mount
    checkForMobile();
    window.addEventListener('resize', checkForMobile);

    return () => {
      // Cleanup on component unmount
      window.removeEventListener('resize', checkForMobile);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(translations).length > 0) {
      const updatedSteps = [
        translations.CompanyInfo,
        translations.OrderInformation,
        translations.ContactPerson,
        translations.ActorType_Student,
        translations.Submit,
      ];
      setSteps(updatedSteps);
    }
  }, [translations]);

  useEffect(() => {
    setFormData(prevData => ({
      ...prevData,
      participants: prevData.participants.slice(0, numberOfCourseParticipants)
    }));
  }, [numberOfCourseParticipants]);


  function checkForMobile() {
    const mobileWidth = 760;
    setIsMobile(window.innerWidth <= mobileWidth);
  }

  function getQueryParameterByName(paramName) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
  }

  function handleChange(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  function handleNumberOfParticipantsChange(event) {
    setNumberOfCourseParticipants(event.target.value);
  };

  const handleParticipantChange = (index, field) => (e) => {
    setFormData(prevData => {
      const participants = [...prevData.participants];
      if (!participants[index]) {
        participants[index] = {};
      }
      participants[index][field] = e.target.value;
      return { ...prevData, participants };
    });
  };

  function handleGDPRChange(e) {
    setGdprConsent(e.target.checked);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (turnstileToken == null) {
      setErrorMessage('Complete the I am not a robot challenge.');
      setShowError(true);
      return;
    }

    try {
      setIsLoading(true);
      const bookingData = { ...formData, turnstileToken, courseParticipants: formData.participants, nbrOfParticipants: numberOfCourseParticipants };
      const response = await fetch(`${API_BASE_URL}/iframe/book`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bookingData),
      });
      const result = await response.json();
      if (result.isSuccessful) {
        navigate('/success', {
          state: {
            translations: translations
          }
        });
      } else {
        if (result.errorMessageTranslationKey === "InvalidTurnstileToken") {
          setErrorMessage(translations.InvalidTurnstileToken)
          setShowError(true);
          setTurnstileToken(null);
          return;
        }

        setErrorMessage('An error occurred during booking.');
        setShowError(true);
      }
    } catch (error) {
      setErrorMessage('An error occurred during booking.');
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  function handleNext() {
    // Function to check if all required fields in a step are filled
    const validateStep = (step) => {
      switch (step) {
        case 0:
          return formData.companyName && formData.companyOrgNo &&
            formData.companyStreet2 && formData.companyZipCode &&
            formData.companyCity;
        case 1:
          return true;
        case 2:
          return formData.contactName && formData.contactEmail && formData.contactPhone;
        case 3:
          return numberOfCourseParticipants > 0;
        default:
          return true;
      }
    };

    // Check if the current step is valid
    if (!validateStep(activeStep)) {
      // This triggers the browser's built-in validation mechanism
      let form = document.querySelector('form');
      if (form.reportValidity) {
        form.reportValidity();
      } else {
        // Fallback for browsers that might not support reportValidity
        setErrorMessage('Please fill in all required fields.');
        setShowError(true);;
      }
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function generateParticipantFields() {
    return Array.from({ length: numberOfCourseParticipants }, (_, index) => (
      <React.Fragment key={index}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ textAlign: 'left', marginTop: '16px', marginBottom: '-8px' }}>
            {translations.ActorType_Student} {index + 1}
          </Typography>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item xs={12} sm={3}>
            <TextField
              name={`participants[${index}].name`}
              label={translations.Name}
              value={formData.participants[index]?.name || ''}
              onChange={handleParticipantChange(index, 'name')}
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name={`participants[${index}].ssn`}
              label={translations.SocialSecurityNumber}
              value={formData.participants[index]?.ssn || ''}
              onChange={handleParticipantChange(index, 'ssn')}
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 20 }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name={`participants[${index}].email`}
              label={translations.Email}
              value={formData.participants[index]?.email || ''}
              onChange={handleParticipantChange(index, 'email')}
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name={`participants[${index}].phone`}
              label={translations.Phone}
              value={formData.participants[index]?.phone || ''}
              onChange={handleParticipantChange(index, 'phone')}
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 20 }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    ));
  };

  function stepper() {
    if (isMobile) {
      return (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: "2em",
          width: '100%'
        }}>
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            sx={{
              width: 'auto',
              minWidth: 'fit-content'
            }}
          />
        </Box>
      );
    }
    return (
      <Stepper activeStep={activeStep} orientation='horizontal' style={{ marginBottom: "2em" }} variant="dots">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <>
            <Typography variant="h6">{translations.CompanyInfo}</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="companyName"
                  label={translations.Name}
                  value={formData.companyName}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="dense"
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="companyOrgNo"
                  label={translations.OrgNo}
                  value={formData.companyOrgNo}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="dense"
                  inputProps={{ maxLength: 30 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="companyStreet2"
                  label={translations.Address2}
                  value={formData.companyStreet2}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="dense"
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="companyZipCode"
                  label={translations.ZipCode}
                  value={formData.companyZipCode}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="dense"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="companyCity"
                  label={translations.City}
                  value={formData.companyCity}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="dense"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
            </Grid>
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="h6">{translations.OrderInformation}</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="purchaseOrderNo"
                  label={translations.PurchaseOrderNumber}
                  value={formData.purchaseOrderNo}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="pdfInvoiceAddress"
                  label={translations.PDFInvoiceAddress}
                  value={formData.pdfInvoiceAddress}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="extraMessage"
                  label={translations.ExtraMessage}
                  value={formData.extraMessage}
                  onChange={handleChange}
                  multiline
                  rows={3}
                  fullWidth
                  margin="dense"
                  inputProps={{ maxLength: 250 }}
                />
              </Grid>
            </Grid>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h6">{translations.ContactPerson}</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="contactName"
                  label={translations.Name}
                  value={formData.contactName}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="dense"
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="contactEmail"
                  label={translations.Email}
                  value={formData.contactEmail}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="dense"
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="contactPhone"
                  label={translations.Phone}
                  value={formData.contactPhone}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="dense"
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>
            </Grid>
          </>
        );
      case 3:
        return (
          <>
            <Typography variant="h6">{translations.ActorType_Student}</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="numberOfCourseParticipants"
                  label={translations.NoParticipants}
                  value={numberOfCourseParticipants}
                  onChange={handleNumberOfParticipantsChange}
                  required
                  fullWidth
                  margin="dense"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              {generateParticipantFields()}
            </Grid>
          </>
        );
      case 4:
        return (
          <>
            {!turnstileToken && (
              <Box display={"flex"} justifyContent={"center"}>
                <Turnstile
                  siteKey="0x4AAAAAAA0cv8SQL08Ju_q1"
                  onSuccess={setTurnstileToken}
                />
              </Box>
            )}

            {/* Content that becomes visible after Turnstile is completed */}
            {turnstileToken && (
              <div>
                <Typography variant="h6">{translations.Submit}</Typography>

                <Box style={{marginLeft: "1em", marginTop: "1em", marginBottom: "1em"}}>
                  <Typography variant="body2" fontWeight="bold">{translations.Course}</Typography>
                  <Typography variant="body2">{translations.CourseType}: {course.licenseDescription}</Typography>
                  <Typography variant="body2">{translations.City}: {course.city}</Typography>
                  <Typography variant="body2">{translations.StartDate}: {new Date(course.fromDate).toLocaleDateString()}</Typography>
                  <Typography variant="body2">{translations.EndDate}: {new Date(course.toDate).toLocaleDateString()}</Typography>

                  <Typography variant="body2" fontWeight="bold" style={{marginTop: "1em"}}>{translations.CompanyInfo}</Typography>
                  <Typography variant="body2">{translations.Name}: {formData.companyName}</Typography>
                  <Typography variant="body2">{translations.OrgNo}: {formData.companyOrgNo}</Typography>
                  <Typography variant="body2">{translations.Address2}: {formData.companyStreet2}</Typography>
                  <Typography variant="body2">{translations.ZipCode}: {formData.companyZipCode}</Typography>
                  <Typography variant="body2">{translations.City}: {formData.companyCity}</Typography>

                  <Typography variant="body2" fontWeight="bold" style={{marginTop: "1em"}}>{translations.OrderInformation}</Typography>
                  <Typography variant="body2">{translations.PurchaseOrderNumber}: {formData.purchaseOrderNo}</Typography>
                  <Typography variant="body2">{translations.PDFInvoiceAddress}: {formData.pdfInvoiceAddress}</Typography>
                  <Typography variant="body2">{translations.ExtraMessage}: {formData.extraMessage}</Typography>

                  <Typography variant="body2" fontWeight="bold" style={{marginTop: "1em"}}>{translations.ContactPerson}</Typography>
                  <Typography variant="body2">{translations.Name}: {formData.contactName}</Typography>
                  <Typography variant="body2">{translations.Email}: {formData.contactEmail}</Typography>
                  <Typography variant="body2">{translations.Phone}: {formData.contactPhone}</Typography>

                  <Typography variant="body2" fontWeight="bold" style={{marginTop: "1em"}}>{translations.ActorType_Student}</Typography>
                  <Typography variant="body2">{translations.NoParticipants}: {numberOfCourseParticipants}</Typography>
                  
                  {formData.participants.map((participant, index) => (
                    <Box key={index} sx={{ mt: 1 }}>
                      <Typography variant="body2" fontWeight="bold">{participant.name}</Typography>
                        <Typography variant="body2">{translations.SocialSecurityNumber}: {participant.ssn}</Typography>
                        <Typography variant="body2">{translations.Email}: {participant.email}</Typography>
                        <Typography variant="body2">{translations.Phone}: {participant.phone}</Typography>
                    </Box>
                  ))}
                </Box>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={gdprConsent}
                      onChange={handleGDPRChange}
                      name="gdprConsent"
                    />
                  }
                  label={translations.GDPRtext}
                />
                <br />
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!gdprConsent}
                  style={{ marginTop: "0.5em" }}
                >
                  {translations.Submit}
                </Button>
              </div>
            )}
          </>
        );
      default:
        return 'Unknown step';
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      <Paper style={{ padding: "1em", marginTop: "2em", marginBottom: "2em" }} variant='outlined'>
        <a href={"/?iframeKey=" + getQueryParameterByName("iframeKey")}>{translations.BackToCourseList}</a>
        <h1>{translations.BookingRequest}</h1>

        {stepper()}

        <form onSubmit={handleSubmit}>
          {getStepContent(activeStep)}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              variant="outlined"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{ mr: 1 }}
            >
              {translations.Back}
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ ml: 1 }}
              disabled={activeStep === steps.length - 1}
            >
              {translations.Next}
            </Button>
          </Box>
        </form>
      </Paper>

      {/* Error Snackbar */}
      <Snackbar open={showError} autoHideDuration={6000} onClose={() => setShowError(false)}>
        <Alert onClose={() => setShowError(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

    </Container>
  );
}


