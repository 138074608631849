import { createTheme } from '@mui/material/styles';

// Function to create a custom theme
export const generateTheme = (primaryHexColor, fontName) => {
  // Ensure the fontName is set to a valid string; default to a system font stack if not provided
  const fontFamily = fontName ? `"${fontName}", sans-serif` : 'Roboto, Arial, sans-serif';


  if (fontName) {
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${fontName.replace(
      ' ',
      '+'
    )}:wght@400;500;700&display=swap`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }

  return createTheme({
    palette: {
      primary: {
        main: primaryHexColor || '#1976d2', // Default blue if no color provided
      },
    },
    typography: {
      fontFamily, // Set the custom font
    },
  });
};